import React, { useContext } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { enqueueSnackbar } from "notistack";
import endpoints from "../endpoints";
import { s2Url } from "@seabrookstudios/types";
import { CachedTokenContext } from "@seabrookstudios/auth";
import { usePreSignedUploadImage, useUploadImage } from "../users/queries";
import { useQueryClient } from "@tanstack/react-query";
import { GamePlayTestImagesCacheKey } from "@seabrookstudios/pitch2table";

registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginImageValidateSize);
registerPlugin(FilePondPluginImageCrop);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImageResize);
registerPlugin(FilePondPluginImageTransform);
registerPlugin(FilePondPluginFileValidateSize);
registerPlugin(FilePondPluginImageExifOrientation);

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestId} props.playTestId
 */
export const UploadImageComponent = ({ gameId, playTestId }) => {
  const token = useContext(CachedTokenContext);
  const queryClient = useQueryClient();

  const onFileUploadSuccess = () => {
    enqueueSnackbar("Play test photo uploaded", { variant: "success" });
  };

  const onFileUploadFailure = () => {
    enqueueSnackbar("An error occurred uploading the photo", { variant: "error" });
  };

  const onAllComplete = () => {
    queryClient.invalidateQueries({ queryKey: GamePlayTestImagesCacheKey(gameId, playTestId) });
  };

  const { games } = endpoints();
  const url = games().playTestImages(gameId, playTestId);
  const uploadImage = useUploadImage(s2Url(url), token, "photo", onFileUploadSuccess, onFileUploadFailure);

  return (
    <FilePond
      allowMultiple
      allowRevert={false}
      allowProcess={false}
      credits={false}
      instantUpload
      allowImageExifOrientation
      allowImageTransform
      maxFiles={10}
      labelIdle={'Drag & Drop up to 10 photos or <span class="filepond--label-action"> Browse </span>'}
      maxFileSize="5MB"
      server={{
        process: uploadImage,
      }}
      onprocessfiles={onAllComplete}
    />
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestId} props.playTestId
 */
export const PremiumUploadPlayTestImageComponent = ({ gameId, playTestId }) => {
  const token = useContext(CachedTokenContext);
  const queryClient = useQueryClient();

  const onFileUploadSuccess = () => {
    enqueueSnackbar("Play test photo uploaded", { variant: "success" });
  };

  const onFileUploadFailure = () => {
    enqueueSnackbar("An error occurred uploading the photo", { variant: "error" });
  };

  const onAllComplete = () => {
    queryClient.invalidateQueries({ queryKey: GamePlayTestImagesCacheKey(gameId, playTestId) });
  };

  const { games } = endpoints();
  const startUrl = games().playTestImagesStart(gameId, playTestId);
  const finishUrl = games().playTestImagesFinish(gameId, playTestId);
  const uploadImage = usePreSignedUploadImage(startUrl, finishUrl, token, onFileUploadSuccess, onFileUploadFailure);

  return (
    <FilePond
      allowMultiple
      allowReplace={false}
      allowRevert={false}
      allowProcess={false}
      credits={false}
      instantUpload={true}
      allowImageExifOrientation
      allowImageTransform
      maxFiles={10}
      labelIdle={'Drag & Drop up to 10 photos or <span class="filepond--label-action"> Browse </span>'}
      maxFileSize="500MB"
      server={{
        process: uploadImage,
      }}
      onprocessfiles={onAllComplete}
    />
  );
};
