import React from "react";
import Image from "mui-image";
import { useGameIdFromRoute, usePlayTestIdFromRoute } from "../games/WithGameIdFromRoute";
import { useDeletePlayTestImage, useGamePlayTestPhotos } from "@seabrookstudios/pitch2table";
import AddPlayTestPhotoFullScreenDialog from "./AddPlayTestPhotoFullScreenDialog";
import Box from "@mui/material/Box";
import CardHeader from "@mui/material/CardHeader";
import Stack from "@mui/material/Stack";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { assetPath } from "../endpoints";
import LinkOff from "@mui/icons-material/LinkOff";
import IconButton from "@mui/material/IconButton";
import Refresh from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Delete from "@mui/icons-material/Delete";

export const PlayTestPhotos = () => {
  const gameId = useGameIdFromRoute();
  const playTestId = usePlayTestIdFromRoute();

  const { data: photos = [], refetch, isRefetching } = useGamePlayTestPhotos(gameId, playTestId);
  const deletePlayTestPhoto = useDeletePlayTestImage(gameId, playTestId);

  const onRefreshHandler = () => {
    refetch();
  };

  /**
   * @param {import("@seabrookstudios/pitch2table-core").AttachmentId} attachmentId
   */
  const deletePhoto = async (attachmentId) => {
    await deletePlayTestPhoto.mutateAsync({ attachmentId });
  };

  return (
    <Box pl={1}>
      <Stack direction="row" justifyContent="space-between">
        <CardHeader title={`Photos (${photos.length})`} />
        <Box p={1}>
          <Stack direction="row">
            <IconButton onClick={onRefreshHandler}>
              {isRefetching ? <CircularProgress size={20} /> : <Refresh color="primary" />}
            </IconButton>
            <AddPlayTestPhotoFullScreenDialog gameId={gameId} playTestId={playTestId} />
          </Stack>
        </Box>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <ImageList cols={3}>
          {photos.map((item) => (
            <ImageListItem key={item.id}>
              <Image
                alt=""
                src={assetPath(item.s3location)}
                fit="cover"
                width={256}
                height={256}
                errorIcon={<LinkOff />}
              />
              <ImageListItemBar
                sx={{
                  background: "transparent",
                }}
                position="top"
                actionIcon={
                  <IconButton onClick={() => deletePhoto(item.id)}>
                    {deletePlayTestPhoto.isPending ? <CircularProgress size={20} /> : <Delete />}
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Stack>
    </Box>
  );
};
