import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CachedTokenContext } from "@seabrookstudios/auth";
import { LoggedoutPage } from "../LoggedOutPage";
import { Path } from "../Path";

export const LoginPage = () => {
  const token = useContext(CachedTokenContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (token === "") {
      return;
    }

    navigate(Path.Home);
  }, [token, navigate]);

  return <LoggedoutPage />;
};
