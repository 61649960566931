import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import reportWebVitals from "./reportWebVitals";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material";
import ConfigProvider from "./config/ConfigProvider";
import { pdfjs } from "react-pdf";
import { AppFrame } from "./AppFrame";
import { theme } from "./theme";
import { routes } from "./AuthedRoute";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto-condensed/400.css";
import "@fontsource/roboto-condensed/700.css";
import "./index.css";
import "./calendar/style.css";
import axios from "axios";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { UseLayoutProvider } from "./layout/use-layout-mode";

axios.defaults.headers.common["x-app-version"] = "web/1.0.44";

pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.js`;

const queryClient = new QueryClient();

const router = createBrowserRouter(routes);

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <UseLayoutProvider>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider>
            <ConfigProvider>
              <CssBaseline />
              <AppFrame>
                <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-gb">
                  <RouterProvider
                    router={router}
                    future={{
                      v7_startTransition: true,
                    }}
                  />
                </LocalizationProvider>
              </AppFrame>
            </ConfigProvider>
          </SnackbarProvider>
        </QueryClientProvider>
      </UseLayoutProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
