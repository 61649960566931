import Forward from "@mui/icons-material/Forward";
import Money from "@mui/icons-material/Money";
import Pause from "@mui/icons-material/Pause";
import Payments from "@mui/icons-material/Payments";
import PointOfSale from "@mui/icons-material/PointOfSale";
import SupportAgent from "@mui/icons-material/SupportAgent";

/**
 * @type {Record<import("@seabrookstudios/pitch2table-core").RoyaltyType, import("@mui/material/OverridableComponent").OverridableComponent<import("@mui/material").SvgIconTypeMap<{}, "svg">> & { muiName: string; }>}
 */
export const RoyaltyTypeIcons = {
  Royalty: Money,
  Advance: Forward,
  HoldingFee: Pause,
  SigningBonus: PointOfSale,
  Consulting: SupportAgent,
  Other: Payments,
};
