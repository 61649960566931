import React from "react";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";

const Label = "Contract Type";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").ContractType} props.value
 * @param {Function} [props.onChange]
 * @param {boolean} [props.disabled]
 */
export const ContractTypeSelect = ({ value, onChange, disabled = false }) => {
  /**
   * @param {*} ev
   */
  const onChangeHandler = (ev) => {
    if (onChange) {
      onChange(ev.target.value);
    }
  };

  return (
    <FormControl sx={{ width: "100%" }} margin="normal" disabled={disabled}>
      <InputLabel id="contract-type">{Label}</InputLabel>
      <Select
        labelId="contract-type"
        value={value}
        onChange={onChangeHandler}
        placeholder={Label}
        label={Label}
        input={<OutlinedInput label={Label} />}
      >
        <MenuItem value="designer-publisher">Designer - Publisher</MenuItem>
        <MenuItem value="co-designer">Designer - Designer</MenuItem>
        <MenuItem value="other">Other</MenuItem>
      </Select>
      <FormHelperText>The contract type helps us classify the contract</FormHelperText>
    </FormControl>
  );
};
