import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import { useMyGames } from "@seabrookstudios/pitch2table";
import { useHotkeys } from "react-hotkeys-hook";
import List from "@mui/material/List";
import { GameListEntry2 } from "./games/ListItem";

export const QuickSearchContext = createContext({ isShowing: false, show: () => {}, hide: () => {} });

/**
 * @param {object} props
 * @param {import("react").ReactNode} props.children
 */
export const QuickSearchDisplay = ({ children }) => {
  const { data: games = [] } = useMyGames();
  const { isShowing, show, hide } = useContext(QuickSearchContext);
  const [filter, setFilter] = useState("");

  useHotkeys("mod+k", () => show());
  useHotkeys("escape", () => hide());

  if (!isShowing) {
    return children;
  }

  const updateFilter = (e) => {
    setFilter(e.target.value);
  };

  const checkForEscape = (e) => {
    if (e.key === "Escape") {
      hide();
    }
  };

  return (
    <>
      {children}
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={isShowing}
        onClick={() => hide()}
      >
        <Box width="66%" style={{ position: "absolute", top: "10%" }}>
          <Card>
            <Box p={2}>
              <TextField
                fullWidth
                label="Search"
                id="search"
                value={filter}
                autoFocus
                onChange={updateFilter}
                onKeyDown={checkForEscape}
              />
              {filter.length > 0 && (
                <List>
                  {games
                    .filter((g) => g.name.toLocaleLowerCase().startsWith(filter.toLocaleLowerCase()))
                    .slice(0, 5)
                    .map((game) => (
                      <GameListEntry2 item={game} />
                    ))}
                </List>
              )}
            </Box>
          </Card>
        </Box>
      </Backdrop>
    </>
    // </QuickSearchContext.Provider>
  );
};

export const QuickSearch = ({ children }) => {
  const [isShowing, setShowModal] = useState(false);

  const show = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  const hide = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const value = useMemo(() => ({ show, hide, isShowing }), [show, hide, isShowing]);

  return (
    <QuickSearchContext.Provider value={value}>
      <QuickSearchDisplay>{children}</QuickSearchDisplay>
    </QuickSearchContext.Provider>
  );
};
