import React, { useContext } from "react";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "@mui/icons-material/Search";
import OutlinedInput from "@mui/material/OutlinedInput";
import platform from "platform";
import { QuickSearchContext } from "../QuickSearch";

export const SearchButton = () => {
  const { show } = useContext(QuickSearchContext);
  const showMeta = platform.manufacturer === "Apple" || platform.os.family === "OS X";

  return (
    <Box onClick={show}>
      <OutlinedInput
        size="small"
        sx={{ width: "15ch" }}
        startAdornment={
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        }
        endAdornment={<InputAdornment position="end">{showMeta ? "⌘" : "Ctrl+"}K</InputAdornment>}
      />
    </Box>
  );
};
