import React from "react";
import { useGame, useGamePlayTestMetaData, useUpdatePlayTest } from "@seabrookstudios/pitch2table";
import Box from "@mui/material/Box";
import CardHeader from "@mui/material/CardHeader";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Star from "@mui/icons-material/Star";
import StarOutline from "@mui/icons-material/StarOutline";
import { AddScoresToPlayTest } from "./AddEditScores";
import Chip from "@mui/material/Chip";
import { PlayTestMetadataComponentTypeLabels } from "@seabrookstudios/pitch2table-core";
import Typography from "@mui/material/Typography";
import { Path } from "../Path";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import IconButton from "@mui/material/IconButton";
import { Link as RouterLink } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Warning from "@mui/icons-material/Warning";
import {
  calculateScoreForPlayer,
  getNonPlayerValue,
  getValueForMetaForPlayer,
  updateValueForMetaForPlayer,
} from "./model";
import CircularProgress from "@mui/material/CircularProgress";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestId} props.playTestId
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestPlayer[]} props.outcomes
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestOutcomes[]} props.nonPlayerOutcomes
 */
export const ReadOnlyScoringComponent = ({ gameId, playTestId, outcomes = [], nonPlayerOutcomes = [] }) => {
  const { data: metadata = [] } = useGamePlayTestMetaData(gameId);
  const { data: game } = useGame(gameId);
  const updatePlayTest = useUpdatePlayTest(gameId, playTestId);

  /**
   * @param {import("@seabrookstudios/pitch2table-core").PlayTestPlayer} player
   */
  const setCalculatedScoreToEnteredScore = (player) => async () => {
    const scoreMeta = metadata.find((m) => m.type === "SCORE");
    if (!scoreMeta) {
      return;
    }

    const calculatedScore = calculateScoreForPlayer(player, metadata);
    if (calculatedScore === undefined) {
      return;
    }

    const updated = updateValueForMetaForPlayer(outcomes, player, scoreMeta.id, calculatedScore);

    await updatePlayTest.mutate({
      date: undefined,
      duration: undefined,
      rulesVersion: undefined,
      complete: undefined,
      audience: undefined,
      body: undefined,
      ignore: undefined,
      medium: undefined,
      nonPlayerOutcomes: undefined,
      outcomes: updated,
    });
  };

  const isUsed = metadata.filter((m) => {
    const used = outcomes
      .map((o) => o.scores)
      .reduce((acc, v) => [...acc, ...v], [])
      .map((s) => s.id);
    return used.includes(m.id);
  });

  const isUsedIds = isUsed.map((m) => m.id);

  const partOfScore = metadata.filter((m) => m.type === "PART_OF_SCORE").filter((m) => isUsedIds.includes(m.id));
  const score = metadata.filter((m) => m.type === "SCORE").filter((m) => isUsedIds.includes(m.id));

  return (
    <Box>
      <Box pl={1}>
        <Stack direction="row" justifyContent="space-between">
          <CardHeader title="Scores" />
          <Box p={1}>
            <Stack direction="row">
              <Tooltip title="Edit play test metadata">
                <IconButton color="primary" to={Path.game(gameId).metadata()} component={RouterLink}>
                  <AccountTreeIcon />
                </IconButton>
              </Tooltip>
              {(outcomes.length > 0 || nonPlayerOutcomes.length > 0) && (
                <AddScoresToPlayTest
                  id="add-scores-to-play-test"
                  gameId={gameId}
                  playTestId={playTestId}
                  editMode
                  outcomes={outcomes}
                  nonPlayerOutcomes={nonPlayerOutcomes}
                  minPlayers={game ? game.minPlayerCounts : 1}
                  maxPlayers={game ? game.maxPlayerCounts : undefined}
                />
              )}
            </Stack>
          </Box>
        </Stack>
      </Box>
      {outcomes.length === 0 && nonPlayerOutcomes.length === 0 && (
        <AddScoresToPlayTest
          id="add-scores-to-play-test"
          gameId={gameId}
          playTestId={playTestId}
          outcomes={outcomes}
          nonPlayerOutcomes={nonPlayerOutcomes}
          minPlayers={game ? game.minPlayerCounts : 1}
          maxPlayers={game ? game.maxPlayerCounts : undefined}
        />
      )}
      {outcomes.length > 0 && (
        <Box pt={2}>
          <CardHeader subheader="Score Breakdown" />
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Typography fontWeight="bold">Winner</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography fontWeight="bold">Player</Typography>
                </TableCell>
                {partOfScore.map((m) => (
                  <TableCell key={`${m.id}-score-breakdown`} align="center">
                    <Typography fontWeight="bold">{m.name}</Typography>
                  </TableCell>
                ))}
                {score.map((m) => (
                  <TableCell key={`${m.id}-score-breakdown`} align="right">
                    <Typography fontWeight="bold">{m.name}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {outcomes.map((player) => {
                return (
                  <TableRow key={`${player.id}-score-breakdown`}>
                    <TableCell align="center" style={{ width: 64 }} size="small">
                      {player.won ? <Star /> : <StarOutline />}
                    </TableCell>
                    <TableCell align="left">{player.name}</TableCell>
                    {partOfScore.map((m) => (
                      <TableCell key={`${player.id}-score-breakdown-${m.id}`} align="center">
                        {getValueForMetaForPlayer(player, m.id)}
                      </TableCell>
                    ))}
                    {score.map((m) => (
                      <TableCell key={`${player.id}-score-breakdown-${m.id}`} align="right">
                        {getValueForMetaForPlayer(player, m.id)}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      )}
      {isUsed.filter((m) => m.type === "PER_PLAYER" || m.type === "PART_OF_SCORE").length > 0 &&
        outcomes.length > 0 && (
          <Box pt={2}>
            <CardHeader subheader="Player Data" />
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Property</TableCell>
                  <TableCell>Type</TableCell>
                  {outcomes.map((player) => (
                    <TableCell key={`${player.id}-name`} align="center">
                      {player.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {isUsed
                  .filter((m) => m.type === "PER_PLAYER")
                  .map((m) => {
                    return (
                      <TableRow key={`${m.id}-per-player`}>
                        <TableCell>{m.name}</TableCell>
                        <TableCell>
                          <Chip label={PlayTestMetadataComponentTypeLabels[m.type]} />
                          {m.archived && <Chip label="Archived" />}
                        </TableCell>
                        {outcomes.map((player) => (
                          <TableCell key={`${player.id}-${m.id}`} align="center">
                            {getValueForMetaForPlayer(player, m.id)}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                {isUsed
                  .filter((m) => m.type === "PART_OF_SCORE")
                  .map((m) => {
                    return (
                      <TableRow key={m.id}>
                        <TableCell>{m.name}</TableCell>
                        <TableCell>
                          <Chip label={PlayTestMetadataComponentTypeLabels[m.type]} />
                          {m.archived && <Chip label="Archived" />}
                        </TableCell>
                        {outcomes.map((player) => (
                          <TableCell key={`${player.id}-{m.id}`} align="center">
                            {getValueForMetaForPlayer(player, m.id)}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                {isUsed.filter((m) => m.type === "PART_OF_SCORE").length > 0 && (
                  <TableRow>
                    <TableCell>Calculated Score</TableCell>
                    <TableCell>
                      <Chip label="Auto" />
                    </TableCell>
                    {outcomes.map((player) => {
                      const calculatedScore = calculateScoreForPlayer(player, metadata);

                      const scoreMeta = metadata.find((m) => m.type === "SCORE");
                      if (!scoreMeta) {
                        return (
                          <TableCell key={`calculated-score-${player.id}`} align="center">
                            {calculatedScore}
                          </TableCell>
                        );
                      }

                      const manualScore = player.scores.find((s) => s.id === scoreMeta.id);
                      if (!manualScore) {
                        return (
                          <TableCell key={`calculated-score-${player.id}`} align="center">
                            {calculatedScore}
                          </TableCell>
                        );
                      }

                      if (manualScore.value === calculatedScore) {
                        return (
                          <TableCell key={`calculated-score-${player.id}`} align="center">
                            {calculatedScore}
                          </TableCell>
                        );
                      }

                      return (
                        <TableCell key={`calculated-score-${player.id}`} align="center">
                          {calculatedScore}
                          <Tooltip title="Calculated score does not match entered value. Click to set entered value to calculated value.">
                            <IconButton onClick={setCalculatedScoreToEnteredScore(player)}>
                              {updatePlayTest.isPending ? <CircularProgress size={16} /> : <Warning color="warning" />}
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        )}
      {metadata.filter((m) => m.type === "PER_GAME").length > 0 && (
        <Box pt={2}>
          <CardHeader subheader="Game Data" />
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Property</TableCell>
                <TableCell>Type</TableCell>
                <TableCell align="right">Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {metadata
                .filter((m) => m.type === "PER_GAME")
                .map((m) => {
                  return (
                    <TableRow key={`${m.id}-game-data`}>
                      <TableCell>{m.name}</TableCell>
                      <TableCell>
                        <Chip label={PlayTestMetadataComponentTypeLabels[m.type]} />
                        {m.archived && <Chip label="Archived" />}
                      </TableCell>
                      <TableCell align="right">{getNonPlayerValue(nonPlayerOutcomes, m.id)}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Box>
      )}
    </Box>
  );
};
