import React from "react";
import { useAllRoyalties } from "@seabrookstudios/pitch2table";
import { idExtractor, useMakeListAndDetail, useMakeListBuilder, useMakeListView } from "../core/useMakeLayout";
import { NoRoyalties } from "./NoRoyalties";
import { RoyaltyTotals } from "./RoyaltyTotals";
import { useTypeFromParam, useYearFromParam } from "./params";
import { RoyaltyFromRouteSansGame } from "../games/royalties/RoyaltyFromRoute";
import {
  useAllRoyaltiesByGame,
  useAllRoyaltiesByPublisher,
  useAllRoyaltiesByType,
  useAllRoyaltiesByYear,
  useAllRoyaltiesForPublisher,
  useAllRoyaltiesForType,
  useAllRoyaltiesForYear,
} from "@seabrookstudios/pitch2table";
import {
  ListEntry,
  ListEntryByPublisher,
  ListEntryByType,
  ListEntryWithGameInfo,
  RoyaltiesByGameListEntry,
  RoyaltiesByPublisherListEntry,
  RoyaltiesByTypeListEntry,
  RoyaltiesByYearListEntry,
} from "./ListEntry";
import { usePublisherIdFromRoute } from "../games/WithGameIdFromRoute";
import { usePublisherNameOrUnlinked } from "../publisher/queries";
import { RoyaltyTypeLabels } from "@seabrookstudios/pitch2table-core";
import { RoyaltyByTypeChart, RoyaltyByYearChart } from "./Chart";
import { Path } from "../Path";

export const RoyaltiesByType = () => {
  const useWrappedQuery = () => useAllRoyaltiesByType();

  const useMakeList = useMakeListBuilder(
    RoyaltiesByTypeListEntry,
    (item) => item[0].type,
    "Royalties by Type",
    NoRoyalties,
    undefined,
    RoyaltyByTypeChart,
    Path.royalties().none()
  );

  const List = useMakeListView(useWrappedQuery, [], "all-royalties", useMakeList);

  return <List />;
};

export const RoyaltiesByPublisher = () => {
  const useWrappedQuery = () => useAllRoyaltiesByPublisher();

  const useMakeList = useMakeListBuilder(
    RoyaltiesByPublisherListEntry,
    (item) => item[0].publisherId,
    "Royalties by Publisher",
    NoRoyalties,
    undefined,
    undefined,
    Path.royalties().none()
  );

  const List = useMakeListView(useWrappedQuery, [], "all-royalties", useMakeList);

  return <List />;
};

export const RoyaltiesByGameId = () => {
  const useWrappedQuery = () => useAllRoyaltiesByGame();

  const useMakeList = useMakeListBuilder(
    RoyaltiesByGameListEntry,
    (item) => item[0].gameId,
    "Royalties by Game",
    NoRoyalties,
    undefined,
    undefined,
    Path.royalties().none()
  );

  const List = useMakeListView(useWrappedQuery, [], "all-royalties", useMakeList);

  return <List />;
};

export const RoyaltiesByYear = () => {
  const useWrappedQuery = () => useAllRoyaltiesByYear();

  const useMakeList = useMakeListBuilder(
    RoyaltiesByYearListEntry,
    (item) => item[0].id,
    "Royalties by Year",
    NoRoyalties,
    undefined,
    RoyaltyByYearChart,
    Path.royalties().none()
  );

  const List = useMakeListView(useWrappedQuery, [], "all-royalties", useMakeList);

  return <List />;
};

export const RoyaltiesListAndDetail = () => {
  const { data: royalties = [] } = useAllRoyalties();

  const useWrappedQuery = () => useAllRoyalties();

  const useMakeList = useMakeListBuilder(
    ListEntry,
    idExtractor,
    "All Royalties",
    NoRoyalties,
    undefined,
    () => <RoyaltyTotals royalties={royalties} />,
    Path.royalties().none()
  );

  const List = useMakeListView(useWrappedQuery, [], "all-royalties", useMakeList);

  return <List />;
};

export const RoyaltyListForYear = () => {
  const year = useYearFromParam();
  const { data: royalties = [] } = useAllRoyaltiesForYear(year);

  const useWrappedQuery = () => useAllRoyaltiesForYear(year);

  const useMakeList = useMakeListBuilder(
    ListEntryWithGameInfo,
    idExtractor,
    year || "",
    NoRoyalties,
    undefined,
    () => <RoyaltyTotals royalties={royalties} />,
    Path.royalties().category("by-year")
  );

  const ListAndDetail = useMakeListAndDetail(
    useWrappedQuery,
    [],
    "all-royalties",
    useMakeList,
    RoyaltyFromRouteSansGame
  );
  return <ListAndDetail />;
};

export const RoyaltyListForType = () => {
  const type = useTypeFromParam();
  const { data: royalties = [] } = useAllRoyaltiesForType(type);

  const useWrappedQuery = () => useAllRoyaltiesForType(type);

  const useMakeList = useMakeListBuilder(
    ListEntryByType,
    idExtractor,
    RoyaltyTypeLabels[type],
    NoRoyalties,
    undefined,
    () => <RoyaltyTotals royalties={royalties} />,
    Path.royalties().category("by-type")
  );

  const ListAndDetail = useMakeListAndDetail(
    useWrappedQuery,
    [],
    "all-royalties",
    useMakeList,
    RoyaltyFromRouteSansGame
  );
  return <ListAndDetail />;
};

export const RoyaltyListForPublisher = () => {
  const publisherId = usePublisherIdFromRoute();
  const { data: royalties = [] } = useAllRoyaltiesForPublisher(publisherId);

  const useWrappedQuery = () => useAllRoyaltiesForPublisher(publisherId);
  const publisherName = usePublisherNameOrUnlinked(publisherId);

  const useMakeList = useMakeListBuilder(
    ListEntryByPublisher,
    idExtractor,
    publisherName,
    NoRoyalties,
    undefined,
    () => <RoyaltyTotals royalties={royalties} />,
    Path.royalties().category("by-publisher")
  );

  const ListAndDetail = useMakeListAndDetail(
    useWrappedQuery,
    [],
    "all-royalties",
    useMakeList,
    RoyaltyFromRouteSansGame
  );
  return <ListAndDetail />;
};
