import React, { useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "@mui/material/Button";
import NoProfile from "@mui/icons-material/Person2Outlined";
import CircularProgress from "@mui/material/CircularProgress";

const LoginButton = ({ overrideLabel, style = {} }) => {
  const { loginWithRedirect, isLoading } = useAuth0();

  const onClickHandler = useCallback(() => loginWithRedirect(), [loginWithRedirect]);

  if (isLoading) {
    return (
      <Button variant="contained" onClick={onClickHandler} style={style}>
        <CircularProgress color="inherit" />
      </Button>
    );
  }

  return (
    <Button variant="contained" onClick={onClickHandler} style={style}>
      {overrideLabel ? overrideLabel : <NoProfile />}
    </Button>
  );
};

export default LoginButton;
